<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form
        ref='form'
        :model='search'
        :style="{'marginBottom':'20px'}"
        :initialValues="search"
        :onFinish='pageSearchChange'
        layout='inline'
      >
        <a-form-item label="关键词" name='keywords' >
          <a-input v-model:value="search.keywords" :style="{width:'250px'}" placeholder="请输入品牌商名称/编号/账号"></a-input>
        </a-form-item>
        <!-- <a-form-item label="审核状态" name='status'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              待处理
            </a-select-option>
            <a-select-option :value="2">
              已通过
            </a-select-option>
            <a-select-option :value="3">
              已拒绝
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="提交时间" name='time'>
          <a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table
      class="tableFixBox" :scroll="{ x: 'max-content'}"
        :pagination='false' 
        :loading='listLoading'
        bordered
        rowKey='id' 
        :columns='columns' 
        :data-source='data'>
        <template v-slot:explain="{ record }">
          <a-popover placement="top">
            <template v-slot:content>
              <p>{{record.apply_reason}}</p>
            </template>
            <template v-slot:title>
              <span>禁用申请说明</span>
            </template>
            <a href="#">查看申请说明</a>
          </a-popover>
        </template>
        <template v-slot:action="{ record }">
          <a-button type="primary" size="small" :disabled="record.status != 1" v-has:brandDisableReview="()=>brandDisableReview(record)">
            审核
          </a-button>
        </template>  
      </a-table>
      <a-pagination 
        :style="{marginTop:'20px',textAlign:'right'}" 
        show-size-changer 
        show-quick-jumper
        :current="search.page" 
        :page-size="search.limit" 
        :total="total" 
        :show-total="total => `总数: ${total} `"
        @change="pageCurrentChange" 
        @showSizeChange="pageSizeChange" />
    </a-card>
    <a-modal v-model:visible="visible" title="品牌商禁用审核" @ok="handleOk">
      <a-radio-group v-model:value="valueRadio" @change="onRadioChange">
        <a-radio :value="2">
          通过
        </a-radio>
        <a-radio :value="3">
          拒绝
        </a-radio>
      </a-radio-group>
      <p class="mt15" v-if='valueRadio == 2' style="color:#f5222d">提示：如果审核通过品牌商将被直接禁用！请谨慎操作！</p>
      <a-textarea
        v-if='valueRadio == 3'
        class="mt15"
        v-model:value="audit_reason"
        placeholder="请输入拒绝的原因"
        auto-size
      />
    </a-modal>
  </a-layout>
</template>
<script>
import {ref,onMounted,reactive,toRefs,inject} from 'vue';
import {message} from 'ant-design-vue'
import {getRouter} from '@/utils/app'
import {setPageState,getPageState} from '@/utils/pageState'
import {$iscode} from '@/utils/app'
import { getBrandtDisableList,setBrandtDisableReview} from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: '',
  status:'',
  page: 1,
  limit: 10,
  start_time:'',
  end_time:''
}
export default {
  components: {},
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({...defSearch});
    // 列表数据和列头配置
    let data = ref([])
    const state = reactive({
      time:[],
      listLoading:false,
      visible:false,
      total:1,
      valueRadio:null,
      audit_reason:'',
      brandItem:{}
    })
    let columns = [{
        title: '品牌商编号',
        dataIndex: 'brand_no',
        key: 'brand_no',
      },
     {
        title: '公司名称',
        dataIndex: 'brand_company',
        key: 'brand_company',
      },
      {
        title: '品牌商账号',
        dataIndex: 'account',
        key: 'account',
      },
 
      {
        title: '所属代理商',
        dataIndex: 'agent_name',
        key: 'agent_name',
      },
      {
        title: '账号余额',
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: '有效期',
        dataIndex: 'signing_time',
        key: 'signing_time',
            customRender: ({ text,record }) => {
            return record.is_certificate_text ?record.is_certificate_text:'-'
          }
      },
      {
        title: '微信授权',
        dataIndex: 'wechat_auth_text',
        key: 'wechat_auth_text',
      },
      {
        title: '认证状态',
        dataIndex: 'is_certificate_text',
        key: 'is_certificate_text',
            customRender: ({ text,record }) => {
            return record.is_certificate_text ?record.is_certificate_text:'-'
          }
      },
      {
        title: '状态',
        dataIndex: 'status_text',
        key: 'status_text',
      },
      {
        title: '提交时间',
        dataIndex: 'create_time',
        key: 'create_time',
      },
      {
        title: '禁用申请说明',
        key: 'explain',
        slots: { customRender: 'explain' },
      },
      {
        title: '操作',
        key: 'action',
        fixed:"right",
        slots: { customRender: 'action' },
      },
    ]
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = {...search.value, page: 1}
      initData(search.value);
    }
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    }
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    }
    //选择时间
    const onChange = (e) => {
      search.value.start_time = e[0]
      search.value.end_time = e[1]
    }
    //打开审核弹窗
    const brandDisableReview = (e) => {
      state.visible = true
      state.brandItem = e
    }
    // 选择审核
    const onRadioChange = (e) => {
      state.valueRadio = e.target.value
    }
    //确认审核
    const handleOk = async() => {
      if(state.valueRadio == null){
        message.error('请选择是否通过审核！')
        return false
      }
      let res = await setBrandtDisableReview({id:state.brandItem.id,status:state.valueRadio,audit_reason:state.audit_reason}).then(res => res.data);
      if ($iscode(res)) {
        state.visible = false
        message.success(res.msg)
        initData(search.value);
      } else {
        message.error(res.message)
      }
    }
    //获取列表
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await getBrandtDisableList(values).then(res => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message)
        }
      } catch (e) {
        state.listLoading = false;
      }
    }

    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if(pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    })
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      onChange,
      handleOk,
      onRadioChange,
      brandDisableReview
    }
  }
}
</script>